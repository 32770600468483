import React from 'react';
import VoiceRecorder from './VoiceRecorder';

function App() {
  return (
      <div className="App">
        <h1>Voice Recorder</h1>
        <VoiceRecorder />
      </div>
  );
}

export default App;
