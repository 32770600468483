import React, { useState, useEffect } from "react";
import "./App.css"; // Make sure to import your CSS file

const VoiceRecorder = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState(null);
    const [recorder, setRecorder] = useState(null);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm; codecs=opus" });
            setRecorder(mediaRecorder);

            const audioChunks = [];
            mediaRecorder.ondataavailable = (event) => audioChunks.push(event.data);

            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
                const url = URL.createObjectURL(audioBlob);
                setAudioURL(url);
            };

            mediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            console.error("Microphone access error:", error);
        }
    };

    const stopRecording = () => {
        if (recorder) {
            recorder.stop();
            setIsRecording(false);
        }
    };

    useEffect(() => {
        return () => {
            if (recorder) recorder.stream.getTracks().forEach((track) => track.stop());
        };
    }, [recorder]);

    return (
        <div>
            <button onClick={isRecording ? stopRecording : startRecording}>
                {isRecording ? "Stop Recording" : "Start Recording"}
            </button>

            {/* Display recording animation */}
            {isRecording && <div className="recording-animation"></div>}

            {audioURL && (
                <div>
                    <h3>Recording</h3>
                    <audio src={audioURL} controls />
                </div>
            )}
        </div>
    );
};

export default VoiceRecorder;
